  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #1977cc;
    border-top-color: #d1e6f9;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #fff;
    height: 40px;
    font-size: 14px;
    transition: all 0.5s;
    z-index: 996;
  }
  
  #topbar.topbar-scrolled {
    top: -40px;
  }
  
  #topbar .contact-info a {
    line-height: 1;
    color: #444444;
    transition: 0.3s;
    text-decoration: none;
  }
  
  #topbar .contact-info a:hover {
    color: #1977cc;
  }

  #topbar .social-links a {
    font-size: 18px;
    display: inline-block;
    /* background: #1977cc; */
    color: #fff;
    /* line-height: 1; */
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    /* height: 36px; */
    transition: 0.3s;
    margin-bottom: 10px;
  }

  #topbar .social-links a img{
    width: 25px;
  }
  #topbar .social-links a .insta{
    width: 37px;
  }
  

  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    top: 40px;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  }
  
  #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
  }

  #header .logo img {
    max-height: 65px;
    width: 65px;
  }
  
  /**
  * Appointment Button *
  */
  .appointment-btn {
    text-decoration: none;
    margin-left: 25px;
    background: #1977cc;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
  }
  
  .appointment-btn:hover {
    background: #166ab5;
    color: #fff;
  }
 
  @media (max-width: 768px) {
    .appointment-btn {
      margin: 0 15px 0 0;
      padding: 6px 18px;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
 
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
    cursor: pointer;
  }
  
  .navbar>ul>li {
    position: relative;
    white-space: nowrap;
    padding: 8px 0 8px 20px;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #2c4964;
    white-space: nowrap;
    transition: 0.3s;
    border-bottom: 2px solid #fff;
    padding: 5px 2px;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
 
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #1977cc;
    border-color: #1977cc;
  }
  
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #2c4964;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  /* Mobile navbar  */

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 998;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 70%;
  background: #fff;
  padding: 20px;
  z-index: 999;
  overflow-y: auto;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu li {
  padding: 10px 0;
}

.mobile-menu a {
  color: #2c4964;
  text-decoration: none;
  font-size: 16px;
  display: block;
  transition: 0.3s;
}

.mobile-menu a:hover {
  color: #357fc0;
}

/* Hide the mobile menu initially */
.mobile-menu-overlay {
  display: none;
}

/* Displayed when the state isMobileMenuOpen is true */
.mobile-menu-overlay,
.mobile-menu {
  display: block;
}
