
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');


*{
  /* border: 1px solid red; */
}
#hero {
  width: 100%;
  height: 90vh;
  background: url('../../../public/hero-bg.jpg') top center;
  background-size: cover;
  margin-bottom: -200px;
  }
  
  #hero .container {
    position: relative;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #2c4964;
  }
  
  #hero h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 24px;
    width: 50%;
    font-family: "poppins", sans-serif;

  }
  
  #hero .btn-get-started {
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #1977cc;
  }
  
  #hero .btn-get-started:hover {
    background: #3291e6;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 992px) {
    #hero {
      margin-bottom: 0;
      height: 100vh;
    }
  
    #hero .container {
      padding-bottom: 63px;
    }
  
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  
  @media (max-height: 600px) {
    #hero {
      height: 110vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 30px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f1f7fd;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2c4964;
  }
  
  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #1977cc;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 20px 0;
    background-color: #f1f7fd;
    min-height: 40px;
    margin-top: 120px;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 100px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs h2 {
      margin: 0 0 10px 0;
    }
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
  
    .breadcrumbs ol {
      display: block;
    }
  
    .breadcrumbs ol li {
      display: inline-block;
    }
  }

  
  
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 30px;
  background: #1977cc;
  border-radius: 4px;
  color: #fff;
}

.why-us .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.why-us .content p {
  margin-bottom: 30px;
}

.why-us .content .more-btn {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.why-us .content .more-btn i {
  font-size: 14px;
}

.why-us .content .more-btn:hover {
  color: #1977cc;
  background: #fff;
}

.why-us .icon-boxes .icon-box {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
}

.why-us .icon-boxes .icon-box i {
  font-size: 40px;
  color: #1977cc;
  margin-bottom: 30px;
}

.why-us .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px 0;
}

.why-us .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}