
/*--------------------------------------------------------------
# Departments
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
.departments {
    overflow: hidden;
  }
  
  .departments .nav-tabs {
    border: 0;
  }
  
  .departments .nav-link {
    cursor: pointer;
    border: 0;
    padding: 12px 15px 12px 0;
    transition: 0.3s;
    color: #2c4964;
    border-radius: 0;
    /* border-right: 2px solid #ebf1f6; */
    border: none !important;
    font-weight: 600;
    font-size: 15px;
  }
  
  .departments .nav-link:hover {
    color: #1977cc;
  }
  
  .departments .nav-link.active {
    color: #1977cc;
    border-color: #1977cc;
  }
  
  .departments .tab-pane.active {
    animation: fadeIn 0.5s ease-out;
  }
  
  .departments .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2c4964;
  }
  #h3{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2c4964;
  }
  
  .departments .details p {
    color: #777777;
  }
  
  .departments .details p:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 992px) {
    .departments .nav-link {
      border: 0;
      padding: 15px;
    }
  
    .departments .nav-link.active {
      color: #fff;
      background: #1977cc;
    }
  }
  