
/*--------------------------------------------------------------
# Appointments
--------------------------------------------------------------*/
.appointment .php-email-form {
    width: 100%;
  }
  
  .appointment .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .appointment .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  
  .appointment .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .appointment .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .appointment .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .appointment .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .appointment .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .appointment .php-email-form input,
  .appointment .php-email-form textarea,
  .appointment .php-email-form select {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    padding: 10px !important;
  }
  
  .appointment .php-email-form input:focus,
  .appointment .php-email-form textarea:focus,
  .appointment .php-email-form select:focus {
    border-color: #1977cc;
  }
  
  .appointment .php-email-form input,
  .appointment .php-email-form select {
    height: 44px;
    font-weight: 600;
  }
  
  .appointment .php-email-form textarea {
    padding: 10px 12px;
    font-weight: 600;
  }
  
  .appointment .php-email-form button[type=submit] {
    background: #1977cc;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .appointment .php-email-form button[type=submit]:hover {
    background: #1c84e3;
  }
  