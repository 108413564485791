
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    /* color: #1977cc; */
    float: left;
    width: 44px;
    height: 44px;
    background: #d6e9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .info i:hover {
    color: white;
  }

  .contact .info .address:hover svg,
  .contact .info .phone:hover svg,
  .contact .info .email:hover svg
  {
    fill: white !important;
  }
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2c4964;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    color: #4b7dab;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #1977cc;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
    font-weight: 600;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
    font-weight: 600;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #1977cc;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #1c84e3;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  