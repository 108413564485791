@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.faq {
  padding: 60px 0;
}

.faq .section-title {
  text-align: center;
}

.faq-list {
  list-style: none;
  padding: 0 2px;
}

.faq-list li {
  margin-bottom: 20px;
  list-style: none;
}

.item {
  overflow: hidden;
  padding: 15px;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.5s ease;
  transform-origin: top;
}

.title {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  cursor: pointer;
}

.title a {
  flex: 1;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500 !important;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #343a40;
}

.title a:hover {
  color: #1977cc;
}
.active a {
  color:#1977cc; 
}

.collapse-title {
  display: flex;
  align-items: center;
}

.collapse-title svg {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.collapse {
  overflow: hidden;
  max-height: 0;
  transform: scaleY(0);
  transition: max-height 0.5s ease, transform 0.5s ease;
}

.collapse.show {
  max-height: 1000px; 
  transform: scaleY(1);
}

.collapse p {
  padding: 15px;
  font-size: 14px;
  margin: 0;
  transition: opacity 0.5s ease;
  font-family: 'poppins';
  color: #343a40;
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
  }
}