
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #d5e1ed;
    padding: 80px 20px;
    overflow: hidden;
    display: inline-block;
    height: 390px;
    text-overflow: ellipsis;
    white-space: break-spaces;
    transition: all ease-in-out 0.1s;
    cursor: pointer;
  }

  .services .icon-box:hover {
    white-space: unset;
    text-overflow: unset;
    height: 100%;
    transition: all ease-in-out 1s;
}
  
  .services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #1977cc;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
    position: relative;
    z-index: 2;
  }
  

  .icon-box:hover .icon svg {
    fill: #1977cc !important;
  }
  .services .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(25, 119, 204, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
    z-index: -1;
  }
  
  .services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .services .icon-box h4 a {
    color: #2c4964;
  }
  
  .services .icon-box p{
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    text-align: justify;
  }

  .services .icon-box ul {
    text-align: justify;
  }

  .services .icon-box h5 {
    text-align: left;
  }
  
  .services .icon-box:hover {
    background: #1977cc;
    border-color: #1977cc;
  }
  
  .services .icon-box:hover .icon {
    background: #fff;
  }
  
  .services .icon-box:hover .icon i {
    color: #1977cc;
  }
  
  .services .icon-box:hover .icon::before {
    background: rgba(255, 255, 255, 0.3);
  }
  
  .services .icon-box:hover h4 a,
  .services .icon-box:hover p,
  .services .icon-box:hover ul,
  .services .icon-box:hover h5
   {
    color: #fff;
  }
  